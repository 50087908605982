import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import { withRouter } from "react-router-dom";
import AclAction from "../Components/ACL/AclAction";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import ScrollToTopBtn from "../Components/ScrollToTop";
import TemplateParser from "../Components/TemplateParser";
import { categories } from "../Store/Actions/CompanyDetails";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Slugify from "../Utils/Slugify";
import Collapsible from "react-collapsible";
import { debounce } from "lodash";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import CustomTitle from "../Components/CustomTitle";
import BoardLoadEventRedirect from "../Utils/BoardLoadEventRedirect";

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () =>
    elRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  return { executeScroll, elRef };
};

class CompanyDetailsContent extends Component {
  loadCompleted = debounce(() => {
    if (this.elScroll.elRef.current != null) {
      this.elScroll.executeScroll();
    }
  }, 1000);
  state = {
    popoverOpen: false,
    showMore: false,
  };

  constructor(props) {
    super(props);
    this.divRef = React.createRef();
    this.showMoreRef = React.createRef();
    this.elScroll = utilizeScroll();
  }

  componentDidMount() {
    this.loadCompleted();
    const location = this.props.location;
    setTimeout(() => {
      BoardLoadEventRedirect(location);
    }, 2200);
  }

  componentDidUpdate() {
    this.handleOverFlow();
    this.loadCompleted();
  }

  handleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  handleOverFlow = () => {
    let element = this.divRef;
    if (element.current && this.showMoreRef.current) {
      if (
        element.current.offsetHeight < element.current.scrollHeight &&
        !this.state.showMore
      ) {
        this.showMoreRef.current.classList.remove("d-none");
      } else {
        this.showMoreRef.current.classList.add("d-none");
      }
    }
  };

  //updating the published date
  updated_published_date = (content, subcategory_id) => {
    let subcategorycontents = content.filter(
      (segment) =>
        segment.subcategory_id === subcategory_id && !segment.is_deleted
    );
    return subcategorycontents[subcategorycontents.length - 1].published_date;
  };

  isSelectedSegmentIR = () => {
    let selectedSegment = this.props.companyDetails?.company?.segments.find(
      (segment) => segment.is_selected
    );
    return !!(
      selectedSegment &&
      selectedSegment["name"]?.search(/.*\sEarnings Response$/i) >= 0
    );
  };

  render() {
    this.isSelectedSegmentIR();
    let companyDetails =
      this.props.company_details && this.props.company_details.companyDetails
        ? this.props.company_details.companyDetails.company
        : [];
    let companyContent =
      this.props.company_details && this.props.company_details.companyContent
        ? this.props.company_details.companyContent
        : [];
    let categories =
      this.props.company_details && this.props.company_details.categories
        ? this.props.company_details.categories
        : [];
    let companyFinancialDetails =
      this.props.company_details &&
      this.props.company_details.companyFinancialDetails &&
      this.props.company_details.companyFinancialDetails.values
        ? this.props.company_details.companyFinancialDetails.values
        : [];
    let revenue = 0;
    let netincome = 0;
    let netprofitmargin = 0;
    if (companyFinancialDetails && companyFinancialDetails.length > 0) {
      let revenueDetails = companyFinancialDetails.filter(
        (fd) => fd.metricCode === constants.TBRI_METRIC_CODES.REVENUE
      );
      if (revenueDetails && revenueDetails.length > 0)
        revenue = revenueDetails[0].amount;
      let netIncomeDetails = companyFinancialDetails.filter(
        (fd) => fd.metricCode === constants.TBRI_METRIC_CODES.OPERATING_INCOME
      );
      if (netIncomeDetails && netIncomeDetails.length > 0)
        netincome = netIncomeDetails[0].amount;
      netprofitmargin = (netincome / revenue) * 100;
    }
    let selectedSegments =
      companyDetails?.id && companyDetails?.segments
        ? companyDetails.segments.filter(
            (segment) => segment.is_selected === true
          )
        : [];
    let selectedSegmentAuthors = selectedSegments[0] ? selectedSegments[0] : [];
    let company_segment_ic_is_active = selectedSegments[0]
      ? selectedSegments[0].company_segment_ic_is_active
      : 0;
    if (companyDetails?.id && selectedSegments[0])
      DocumentTitle(`${companyDetails.name} - ${selectedSegments[0].name}`);
    return (
      <aside className=" dash_board_content inner-sidebar-right-content">
        <UncontrolledPopover
          placement="bottom"
          target="PopoverLegacy2"
          trigger="legacy"
          className="custom-popover"
        >
          <PopoverBody>
            <div className="d-flex flex-column company_details_more_options">
              {/* Hiding Quant Data from Company Reports as per requirment */}
              {/* {this.isSelectedSegmentIR() ? (
                <></>
              ) : (
                <>
                  <span
                    onClick={() => {
                      this.props.handleQuantDataRedirection();
                    }}
                  >
                    <span
                      className="material-icons-outlined mr-2 icon"
                      data-for={`tooltip-${Slugify(
                        constants.COMPANY.VIEW.REDIRECT_TO_BENCHMARKS_TOOLTIP
                      )}`}
                      data-iscapture="true"
                    >
                      hub
                    </span>
                    <span>Quant Data</span>
                  </span>
                </>
              )} */}
              <span
                onClick={() => {
                  this.props.handleBenchmarksRedirection();
                }}
              >
                <span
                  className="material-icons-outlined mr-2 icon"
                  data-for={`tooltip-${Slugify(
                    constants.COMPANY.VIEW.REDIRECT_TO_BENCHMARKS_TOOLTIP
                  )}`}
                  data-iscapture="true"
                >
                  speed
                </span>
                <span>
                  {constants.COMPANY.VIEW.REDIRECT_TO_BENCHMARKS_TOOLTIP}
                </span>
              </span>
              <span
                onClick={() => {
                  this.props.handleSpecialReportRedirection();
                }}
              >
                <span
                  className="material-icons-outlined mr-2 icon"
                  data-for={`tooltip-${Slugify(
                    constants.COMPANY.VIEW.REDIRECT_TO_SPECIAL_REPORTS_TOOLTIP
                  )}`}
                  data-iscapture="true"
                >
                  summarize
                </span>
                <span>
                  {constants.COMPANY.VIEW.REDIRECT_TO_SPECIAL_REPORTS_TOOLTIP}
                </span>
              </span>
              <span
                onClick={() => {
                  this.props.handleMarketForecastsRedirection();
                }}
              >
                <span className="material-icons-outlined mr-2 icon">
                  trending_up
                </span>
                <span>
                  {constants.COMPANY.VIEW.REDIRECT_TO_MARKET_FORECASTS_TOOLTIP}
                </span>
              </span>
              <span
                onClick={() => {
                  this.props.handleMarketLandscapeRedirection();
                }}
              >
                <span className="material-icons-outlined mr-2 icon">
                  analytics
                </span>
                <span>
                  {constants.COMPANY.VIEW.REDIRECT_TO_MARKET_LANDSCAPES_TOOLTIP}
                </span>
              </span>
              <span
                onClick={() => {
                  this.props.documentModalHandler();
                }}
                className={companyContent.length ? "" : "inactive"}
              >
                <span
                  className="material-icons-outlined mr-2 icon"
                  data-for={`tooltip-${Slugify(
                    constants.COMPANY.VIEW.FILES_TOOLTIP
                  )}`}
                  data-iscapture="true"
                >
                  file_copy
                </span>
                <span>{constants.COMPANY.VIEW.FILES_TOOLTIP}</span>
              </span>
              {/* <AclAction
                type={constants.ACL.PERMISSION_KEY}
                entity={constants.ACL.COMPANY_RESOURCE.CONTENT_TITLE}
                action={constants.ACL.COMPANY_RESOURCE.ACTIONS.EXPORT}
              >
                <span
                  tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                  onClick={() => {
                    this.props.handleDownload(
                      revenue,
                      netincome,
                      netprofitmargin
                    );
                  }}
                  className={companyContent.length ? "" : "inactive"}
                >
                  <span
                    className="material-icons-outlined mr-2 icon"
                    data-for={`tooltip-${Slugify(
                      constants.COMPANY.VIEW.DOWNLOAD_TOOLTIP
                    )}`}
                    data-iscapture="true"
                  >
                    file_download
                  </span>
                  <span>{constants.COMPANY.VIEW.DOWNLOAD_TOOLTIP}</span>
                </span>
              </AclAction> */}
            </div>
          </PopoverBody>
        </UncontrolledPopover>

        {companyDetails?.id &&
          companyDetails.segments &&
          companyDetails.segments.length > 0 && (
            <div className="segments-card-wrapper btn-items-card-wrapper">
              <div
                ref={this.divRef}
                className={
                  this.state.showMore
                    ? "card custom-card segments-card btn-items-card open-card"
                    : "card custom-card segments-card btn-items-card closed-card"
                }
              >
                <div className="card-body">
                  <div className="d-flex">
                    <h3 className="font-weight-semibold mb-0 text-black2 title">
                      Segments
                    </h3>
                    <ul className="segment-items btn-items d-flex flex-wrap">
                      {companyDetails.segments
                        .sort((a, b) =>
                          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                        )
                        .map((segment, index) => (
                          <li
                            onClick={() =>
                              this.props.callRecentlyView(
                                companyDetails.id,
                                segment.id
                              )
                            }
                            key={segment.id}
                            className={`${
                              segment.is_selected === true ? "active" : ""
                            } ${segment.allowed_access ? "" : ""} `}
                          >
                            <div
                              className={`${
                                segment.allowed_access ? "" : ""
                              } content font-weight-semibold d-flex align-items-center`}
                              onClick={() => {
                                this.props.switchSegmentHandler(
                                  segment.id,
                                  segment.allowed_access
                                );
                              }}
                            >
                              {segment.allowed_access ? (
                                segment.company_segment_ic_is_active ===
                                1 ? null : (
                                  <span className="material-icons-round mr-2 not-allowed-access icon">
                                    report_problem
                                  </span>
                                )
                              ) : (
                                <span class="icon-fs-18 material-icons-round mr-2 not-allowed-access">
                                  lock
                                </span>
                              )}
                              <span>{segment.name}</span>
                            </div>
                          </li>
                        ))}
                    </ul>
                    <div className="align-self-start d-flex mt-1 show-more-btn ml-auto">
                      <button
                        ref={this.showMoreRef}
                        type="button"
                        className="btn btn-link font-weight-semibold d-none"
                        onClick={this.handleShowMore}
                      >
                        show more
                      </button>
                      {this.state.showMore ? (
                        <span
                          className="material-icons-round close-icon"
                          onClick={this.handleShowMore}
                        >
                          close
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        <ScrollToTopBtn />
        {companyDetails?.id && companyDetails.segments && companyContent && (
          <div className="">
            <div className="row company_details">
              <aside
                className={
                  "" +
                  (this.props.isRelatedInsightsVisible
                    ? "col-lg-10"
                    : "col-lg-12")
                }
              >
                {company_segment_ic_is_active === 0 && (
                  <div className="row mt-1">
                    <div className="col-12">
                      <div
                        className="alert alert-warning d-flex align-items-center"
                        role="alert"
                      >
                        <span class="icon icon-fs-16 material-icons-round mr-2">
                          report_problem
                        </span>
                        Attention: This segment is inactive and not currently
                        updated by TBR.
                      </div>
                    </div>
                  </div>
                )}
                {company_segment_ic_is_active !== 0 &&
                  companyContent.length == 0 && (
                    <div className="row mt-1">
                      <div className="col-12">
                        <div
                          class="alert alert-secondary d-flex align-items-center"
                          role="alert"
                        >
                          <span class="icon icon-fs-16 material-icons-round mr-2">
                            report_problem
                          </span>
                          Attention: No Content Found.
                        </div>
                      </div>
                    </div>
                  )}

                <div
                  className={`${
                    companyDetails?.id &&
                    companyDetails.segments &&
                    companyDetails.segments.length > 0
                      ? ""
                      : "no-segments-card-wrapper"
                  }`}
                >
                  {companyContent.length > 0 && (
                    <>
                      {categories.map(
                        (category, index) =>
                          companyContent.find(
                            (cc) => cc.category_id === category.id
                          ) && (
                            <>
                              <div className="custom-collapser custom-collapser2 company-details-collapser details-page-collapser">
                                <div
                                  className={`category-wrapper ${
                                    category.subcategories.find(
                                      (s) => s.is_selected === true
                                    )
                                      ? ""
                                      : "d-none"
                                  }`}
                                  id={`${Slugify(category.name)}_${
                                    category.id
                                  }`}
                                >
                                  <Collapsible
                                    open={category.is_open}
                                    handleTriggerClick={async () => {
                                      let cat = categories.findIndex(
                                        (c) => c.id === category.id
                                      );
                                      categories[cat].is_open =
                                        !categories[cat].is_open;
                                      await this.props.categories([
                                        ...categories,
                                      ]);
                                    }}
                                    trigger={
                                      <div className="collapse-trigger">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="d-flex align-items-center">
                                            <h3 className="font-weight-bold mb-0 title category_name">
                                              {category.name}
                                            </h3>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className="collapse-icon-wrapper">
                                              <span className="material-icons-round add icon">
                                                add
                                              </span>
                                              <span className="material-icons-round remove icon">
                                                remove
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  >
                                    {category.subcategories.map(
                                      (sub_category, sub_index) =>
                                        companyContent &&
                                        companyContent.length > 0 &&
                                        companyContent.filter(
                                          (segment) =>
                                            segment.subcategory_id ===
                                            sub_category.id
                                        ).length > 0 &&
                                        sub_category.is_selected && (
                                          <section
                                            name={`${Slugify(
                                              sub_category.name
                                            )}_${sub_category.id}`}
                                            id={`${Slugify(
                                              sub_category.name
                                            )}_${sub_category.id}`}
                                            className={`row ${
                                              sub_category.mode
                                            } ${
                                              sub_category.is_selected
                                                ? ""
                                                : "d-none"
                                            }`}
                                            key={sub_index}
                                          >
                                            <div
                                              className="tbr_position col-12"
                                              key={sub_index}
                                            >
                                              <div className="collapse-panel-content">
                                                <div className="sub_category_heading d-flex align-items-center">
                                                  <h3 className="heading mb-0">
                                                    {sub_category.name}
                                                  </h3>
                                                  {sub_category.mode !==
                                                    "news" &&
                                                    companyContent.filter(
                                                      (segment) =>
                                                        segment.subcategory_id ===
                                                        sub_category.id
                                                    )[0].published_date && (
                                                      <div className="d-flex align-items-center">
                                                        <div className="published-date">
                                                          Published:{" "}
                                                          {ComapnyDetailsDateTimeFormatter(
                                                            this.updated_published_date(
                                                              companyContent,
                                                              sub_category.id
                                                            )
                                                          )}
                                                        </div>
                                                        <AclAction
                                                          type={
                                                            constants.ACL
                                                              .PERMISSION_KEY
                                                          }
                                                          entity={
                                                            constants.ACL
                                                              .MY_BOARDS_RESOURCE
                                                              .TITLE
                                                          }
                                                          action={
                                                            constants.ACL
                                                              .MY_BOARDS_RESOURCE
                                                              .ACTIONS.PIN
                                                          }
                                                        >
                                                          <div
                                                            tbrentitytype={
                                                              constants.ACL
                                                                .ENTITY_TYPE
                                                                .BUTTON
                                                            }
                                                            className={`add_to_board ${
                                                              sub_category.show_actions
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                          >
                                                            {/* <span className="open_Onboard" onClick={() => { this.props.togglePinBoardActions(category.id, sub_category.id) }}><i className="fa fa-ellipsis-v dots-icon" aria-hidden="false" /></span> */}
                                                            <div
                                                              className="add_to_board_box align-items-center justify-content-center d-flex"
                                                              onClick={() => {
                                                                this.props.addToBoard(
                                                                  category.id,
                                                                  sub_category.id
                                                                );
                                                              }}
                                                            >
                                                              <span
                                                                class="material-icons-outlined"
                                                                data-for={`tooltip-${Slugify(
                                                                  constants
                                                                    .APPLICATION_ROUTE
                                                                    .MY_BOARDS
                                                                    .PIN_TO_BOARD
                                                                    .TOOLTIP
                                                                )}`}
                                                                data-tip={
                                                                  constants
                                                                    .APPLICATION_ROUTE
                                                                    .MY_BOARDS
                                                                    .PIN_TO_BOARD
                                                                    .TOOLTIP
                                                                }
                                                                data-iscapture="true"
                                                              >
                                                                <CustomReactTooltip
                                                                  id={`tooltip-${Slugify(
                                                                    constants
                                                                      .APPLICATION_ROUTE
                                                                      .MY_BOARDS
                                                                      .PIN_TO_BOARD
                                                                      .TOOLTIP
                                                                  )}`}
                                                                  multiline={
                                                                    true
                                                                  }
                                                                />
                                                                push_pin
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </AclAction>
                                                      </div>
                                                    )}
                                                </div>
                                                <div className="sub-category-content">
                                                  {companyContent
                                                    .filter(
                                                      (segment) =>
                                                        segment.subcategory_id ===
                                                          sub_category.id &&
                                                        segment.is_deleted === 0
                                                    )
                                                    .map((sub_category1) => (
                                                      <div>
                                                        {sub_category1.id ===
                                                          this.props.contentId}
                                                        {sub_category1.subcategory_title && (
                                                          <div
                                                            className="desipte_sec"
                                                            key={
                                                              sub_category1.id
                                                            }
                                                          >
                                                            <CustomTitle
                                                              ref={
                                                                sub_category1.id ===
                                                                  this.props
                                                                    .contentId &&
                                                                this.elScroll
                                                                  .elRef
                                                              }
                                                              id={
                                                                sub_category1.id
                                                              }
                                                              tag="h4"
                                                              className="heading mb-0 font-default"
                                                              title={
                                                                sub_category1.subcategory_title
                                                                  ? sub_category1.subcategory_title
                                                                  : ""
                                                              }
                                                              title_html={
                                                                sub_category1.subcategory_title_html
                                                              }
                                                            />
                                                          </div>
                                                        )}
                                                        <TemplateParser
                                                          isCompany={true}
                                                          key={`temp-${sub_category1.id}`}
                                                          template={
                                                            sub_category1
                                                              .template.content
                                                          }
                                                          content={
                                                            sub_category1.data
                                                          }
                                                        />
                                                      </div>
                                                    ))}
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        )
                                    )}
                                  </Collapsible>
                                </div>
                              </div>
                            </>
                          )
                      )}
                    </>
                  )}
                </div>
              </aside>
              {this.props.isRelatedInsightsVisible && (
                <aside className="col-lg-2">
                  <div className="related_insights">
                    <h3>Related Insights</h3>
                    <ol className="list_style">
                      <li>
                        <a href="/">Related Insights-1</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-2</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-3</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-4</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-5</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-6</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-7</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-8</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-9</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-10</a>
                      </li>
                    </ol>
                  </div>
                </aside>
              )}
            </div>
          </div>
        )}
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company_details: state.company_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsContent)
);
