import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import AclAction from "../Components/ACL/AclAction";

import CustomReactTooltip from "../Components/CustomReactTooltip";
import ScrollToTopBtn from "../Components/ScrollToTop";
import TemplateParser from "../Components/TemplateParser";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Slugify from "../Utils/Slugify";
import Collapsible from "react-collapsible";
import { categories } from "../Store/Actions/ProjectDetails";
import CustomTitle from "../Components/CustomTitle";
import { withRouter } from "react-router-dom";
import BoardLoadEventRedirect from "../Utils/BoardLoadEventRedirect";

class ProjectDetailsContent extends Component {
  breadcrumbs = () => {
    let projectDetails =
      this.props.project_details && this.props.project_details.projectDetails
        ? this.props.project_details.projectDetails
        : {};
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.PROJECT.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.PROJECT.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: projectDetails.id
          ? projectDetails.name
          : constants.APPLICATION_ROUTE.PROJECT.VIEW.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  componentDidMount() {
    const { location } = this.props;
    setTimeout(() => {
      BoardLoadEventRedirect(location);
    }, 2200);
  }

  render() {
    let projectContent =
      this.props.project_details && this.props.project_details.projectContent
        ? this.props.project_details.projectContent
        : [];
    let categories =
      this.props.project_details && this.props.project_details.categories
        ? this.props.project_details.categories
        : [];
    let projectDetails =
      this.props.project_details &&
      this.props.project_details.projectDetails &&
      this.props.project_details.projectDetails.project
        ? this.props.project_details.projectDetails.project
        : {};
    if (projectDetails?.id) DocumentTitle(`${projectDetails.name}`);
    return (
      <aside className="dash_board_content inner-sidebar-right-content">
        <ScrollToTopBtn />
        {projectContent && (
          <div className="">
            <div className="row company_details">
              <aside
                className={
                  "" +
                  (this.props.isRelatedInsightsVisible
                    ? "col-lg-10"
                    : "col-lg-12")
                }
              >
                <div className="no-segments-card-wrapper">
                  {projectContent.length > 0 && (
                    <>
                      {categories.map(
                        (category, index) =>
                          projectContent.find(
                            (cc) => cc.category_id === category.id
                          ) && (
                            <>
                              <div
                                className={`custom-collapser custom-collapser2 projects-details-collapser details-page-collapser`}
                              >
                                <div
                                  className={`category-wrapper ${
                                    category.subcategories.find(
                                      (s) => s.is_selected === true
                                    )
                                      ? ""
                                      : "d-none"
                                  }`}
                                  id={`${Slugify(category.name)}_${
                                    category.id
                                  }`}
                                >
                                  <Collapsible
                                    open={category.is_open}
                                    handleTriggerClick={() => {
                                      let cat = categories.findIndex(
                                        (c) => c.id === category.id
                                      );
                                      categories[cat].is_open =
                                        !categories[cat].is_open;
                                      this.props.categories([...categories]);
                                    }}
                                    trigger={
                                      <div className="collapse-trigger">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="d-flex align-items-center">
                                            <h3 className="font-weight-bold mb-0 title category_name">
                                              {category.name}
                                            </h3>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className="collapse-icon-wrapper">
                                              <span class="material-icons-round add icon">
                                                add
                                              </span>
                                              <span class="material-icons-round remove icon">
                                                remove
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  >
                                    {category.subcategories.map(
                                      (sub_category, sub_index) =>
                                        projectContent &&
                                        projectContent.length > 0 &&
                                        projectContent.filter(
                                          (segment) =>
                                            segment.subcategory_id ===
                                            sub_category.id
                                        ).length > 0 &&
                                        sub_category.is_selected && (
                                          <section
                                            name={`${Slugify(
                                              sub_category.name
                                            )}_${sub_category.id}`}
                                            id={`${Slugify(
                                              sub_category.name
                                            )}_${sub_category.id}`}
                                            className={`row ${
                                              sub_category.mode
                                            } ${
                                              sub_category.is_selected
                                                ? ""
                                                : "d-none"
                                            }`}
                                            key={sub_index}
                                          >
                                            <div
                                              className="tbr_position col-12"
                                              key={sub_index}
                                            >
                                              <div className="collapse-panel-content">
                                                <div className="sub_category_heading d-flex align-items-center">
                                                  <h3
                                                    className="heading mb-0"
                                                    title={sub_category.name}
                                                  >
                                                    {sub_category.name}
                                                  </h3>

                                                  {projectContent.filter(
                                                    (segment) =>
                                                      segment.subcategory_id ===
                                                      sub_category.id
                                                  )[0].published_date && (
                                                    <div className="d-flex align-items-center">
                                                      <div className="published-date">
                                                        Published:{" "}
                                                        {ComapnyDetailsDateTimeFormatter(
                                                          projectContent.filter(
                                                            (segment) =>
                                                              segment.subcategory_id ===
                                                              sub_category.id
                                                          )[0].published_date
                                                        )}
                                                      </div>
                                                      {this.props
                                                        .aclActionVisible ? (
                                                        <AclAction
                                                          type={
                                                            constants.ACL
                                                              .PERMISSION_KEY
                                                          }
                                                          entity={
                                                            constants.ACL
                                                              .MY_BOARDS_RESOURCE
                                                              .TITLE
                                                          }
                                                          action={
                                                            constants.ACL
                                                              .MY_BOARDS_RESOURCE
                                                              .ACTIONS.PIN
                                                          }
                                                        >
                                                          <div
                                                            tbrentitytype={
                                                              constants.ACL
                                                                .ENTITY_TYPE
                                                                .BUTTON
                                                            }
                                                            className={`add_to_board ${
                                                              sub_category.show_actions
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                          >
                                                            <div
                                                              className="add_to_board_box align-items-center justify-content-center d-flex"
                                                              onClick={() => {
                                                                this.props.addToBoard(
                                                                  category.id,
                                                                  sub_category.id
                                                                );
                                                              }}
                                                            >
                                                              <span
                                                                class="material-icons-outlined"
                                                                data-for={`tooltip-${Slugify(
                                                                  constants
                                                                    .APPLICATION_ROUTE
                                                                    .MY_BOARDS
                                                                    .PIN_TO_BOARD
                                                                    .TOOLTIP
                                                                )}`}
                                                                data-tip={
                                                                  constants
                                                                    .APPLICATION_ROUTE
                                                                    .MY_BOARDS
                                                                    .PIN_TO_BOARD
                                                                    .TOOLTIP
                                                                }
                                                                data-iscapture="true"
                                                              >
                                                                <CustomReactTooltip
                                                                  id={`tooltip-${Slugify(
                                                                    constants
                                                                      .APPLICATION_ROUTE
                                                                      .MY_BOARDS
                                                                      .PIN_TO_BOARD
                                                                      .TOOLTIP
                                                                  )}`}
                                                                  multiline={
                                                                    true
                                                                  }
                                                                />
                                                                push_pin
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </AclAction>
                                                      ) : (
                                                        <div
                                                          tbrentitytype={
                                                            constants.ACL
                                                              .ENTITY_TYPE
                                                              .BUTTON
                                                          }
                                                          className={`add_to_board ${
                                                            sub_category.show_actions
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                        >
                                                          <div
                                                            className="add_to_board_box align-items-center justify-content-center d-flex"
                                                            onClick={() => {
                                                              this.props.addToBoard(
                                                                category.id,
                                                                sub_category.id
                                                              );
                                                            }}
                                                          >
                                                            <span class="material-icons-outlined">
                                                              push_pin
                                                            </span>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="sub-category-content">
                                                  {projectContent
                                                    .filter(
                                                      (segment) =>
                                                        segment.subcategory_id ===
                                                          sub_category.id &&
                                                        segment.is_deleted === 0
                                                    )
                                                    .map(
                                                      (
                                                        sub_category1,
                                                        sub_index1
                                                      ) => (
                                                        <>
                                                          {sub_category1.subcategory_title && (
                                                            <div
                                                              className="desipte_sec"
                                                              key={
                                                                sub_category1.id
                                                              }
                                                            >
                                                              <CustomTitle
                                                                tag="h4"
                                                                className="heading mb-0 font-default"
                                                                title={
                                                                  sub_category1.subcategory_title
                                                                    ? sub_category1.subcategory_title
                                                                    : ""
                                                                }
                                                                title_html={
                                                                  sub_category1.subcategory_title_html
                                                                }
                                                              />
                                                            </div>
                                                          )}
                                                          <TemplateParser
                                                            key={`temp-${sub_category1.id}`}
                                                            template={
                                                              sub_category1
                                                                .template
                                                                .content
                                                            }
                                                            content={
                                                              sub_category1.data
                                                            }
                                                          />
                                                        </>
                                                      )
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        )
                                    )}
                                  </Collapsible>
                                </div>
                              </div>
                            </>
                          )
                      )}
                    </>
                  )}
                </div>
                {projectDetails && projectDetails.is_active === 0 && (
                  <div className="mb-2 mt-4 btn-items-card-wrapper">
                    <div className="card custom-card segments-card btn-items-card">
                      <div className="card-body alert-warning">
                        <div className="d-flex align-items-center">
                          <span className="material-icons-round mr-2 icon-fs-16">
                            report_problem
                          </span>
                          <span>
                            Attention: This Project is inactive and not
                            currently updated by TBR.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {projectContent.length == 0 && projectDetails.is_active != 0 && (
                  <div className="row mt-1">
                    <div className="col-12">
                      <div
                        className="alert alert-secondary d-flex align-items-center"
                        role="alert"
                      >
                        <span class="icon icon-fs-16 material-icons-round mr-2">
                          report_problem
                        </span>
                        Attention: No Content Found.
                      </div>
                    </div>
                  </div>
                )}
              </aside>
              {this.props.isRelatedInsightsVisible && (
                <aside className="col-lg-2">
                  <div className="related_insights">
                    <h3>Related Insights</h3>
                    <ol className="list_style">
                      <li>
                        <a href="/">Related Insights-1</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-2</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-3</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-4</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-5</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-6</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-7</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-8</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-9</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-10</a>
                      </li>
                    </ol>
                  </div>
                </aside>
              )}
            </div>
          </div>
        )}
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project_details: state.project_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsContent)
);
