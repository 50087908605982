import React, { useState, useEffect } from "react";
import SearchComponent from "../../Components/searchComponent";
import Collapsible from "react-collapsible";
import { ResourceAndFaqServices } from "./ResourceAndFaqServices";
import { useHistory } from "react-router-dom";
import { NoDataAvailable } from "./Resources";
const formatNumber = (number) => {
  return number < 10 ? `0${number}` : number.toString();
};
function FaqItem(props) {
  return (
    <div
      className="faq-collapser-item"
      style={{
        borderTop: props.index > 0 ? "1px solid #d9d9d9" : "none",
        padding: "15px 10px",
      }}
    >
      <Collapsible
        open={props.collapseId === props.index}
        trigger={
          <div
            onClick={() =>
              props.setCollapseId(
                props.collapseId === props.index ? null : props.index
              )
            }
            className="faq-collapse cursor-pointer"
          >
            <h2 className="faq-collapse-header d-flex align-item-center">
              <span className="span_1">{formatNumber(props.index + 1)}</span>
              <span className="span_2">{props.item.question}</span>
            </h2>
            <div className="d-flex g-10 align-item-center">
              <span className="faq-add-icon material-icons-outlined material-icon mr-2">
                {props.collapseId === props.index
                  ? "expand_less"
                  : "expand_more"}
              </span>
            </div>
          </div>
        }
      >
        <div className="faq-collapse-list curser-pointer">
          <div dangerouslySetInnerHTML={{ __html: props.item.answer }} />
        </div>
      </Collapsible>
    </div>
  );
}
function Faqs() {
  const [faqList, setFaqList] = useState([]);
  const [search, setSearch] = useState("");
  const [collapseId, setCollapseId] = useState(null);
  const history = useHistory();

  const handleSearch = async () => {};
  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const fetchFaqs = async (pageNumber) => {
    let pageNo = pageNumber || 1;
    // Set default values if parameters are not provided
    let queryParams = "";

    if (search !== "") {
      queryParams += "&question=" + search;
    }

    // Update the browser history
    history.push({
      pathname: history.location.pathname,
      search: "?page=" + pageNo + queryParams,
    });

    let url = `/faq-resources/faqs?status=1&limit=100&sort=order`;

    if (pageNo) {
      url = url + `&page=${pageNo}`;
    }
    if (search) {
      url = url + `&question=${search}`;
    }

    let res = await ResourceAndFaqServices.getList(url);
    setFaqList(res.data.data);
  };

  useEffect(() => {
    fetchFaqs();
  }, [search]);
  return (
    <div>
      <div className="d-flex justify-content-space-between">
        <span className="title-text f-23 fw-700">
          Frequently Asked Questions
        </span>
        <div className="d-flex g-10">
          <SearchComponent
            placeHolder="Search Question"
            handleSearch={handleSearch}
            handleInput={handleInput}
            value={search}
          />
        </div>
      </div>
      {faqList.length > 0 ? (
        <div className="faq">
          {faqList.map((item, index) => (
            <FaqItem
              key={index}
              collapseId={collapseId}
              setCollapseId={setCollapseId}
              index={index}
              item={item}
            />
          ))}
        </div>
      ) : (
        <NoDataAvailable text="FAQs" />
      )}
    </div>
  );
}

export default Faqs;
